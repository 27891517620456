<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: updatePassword.vue
Description: This file contains form to update user password
-->
<template>
  <div class="update-password-details" ref="updatePasswordDetails">
    <p>{{ `${$t("Comn_chng_pwd")}` }}</p>
    <el-alert
      v-if="ehm__unhandledErrorMessage"
      :title="$t(ehm__unhandledErrorMessage)"
      @close="ehm__handleAlertClose"
      type="error"
    ></el-alert>

    <el-row class="update_pass_form">
      <er-form
        ref="updatePasswordForm"
        v-loading="loading"
        element-loading-background="white"
        :model="formData"
        label-position="top"
        size="small"
      >
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
          "
        >
          <div>
            <er-form-item
              prop="current_password"
              :label="userFormLabels[0].label"
              :error="
                aekmm__castErrorKeyToLang(
                  ehm__errMessagesObject.current_password
                )
              "
            >
              <er-input
                v-model="formData.current_password"
                class="input-box"
                :placeholder="userFormLabels[0].placeholder"
                :maxlength="userFieldLengths.max_password_length"
                show-password
              >
              </er-input>
            </er-form-item>
          </div>
          <div>
            <er-form-item
              prop="new_password"
              :label="userFormLabels[1].label"
              :error="
                aekmm__castErrorKeyToLang(ehm__errMessagesObject.password)
              "
            >
              <er-input
                v-model="formData.password"
                class="er-input--password"
                ref="passwordField"
                :placeholder="userFormLabels[1].placeholder"
                show-password
                @input="pvm__getPercentage"
                :maxlength="userFieldLengths.max_password_length"
                :style="`--width:${pvm__passwordPercentage.width}px;--color:${pvm__passwordPercentage.color};--left:${pvm__passwordPercentage.left}px`"
              >
              </er-input>
            </er-form-item>
          </div>
          <div>
            <er-form-item
              prop="confirm_password"
              :label="userFormLabels[2].label"
              :error="
                aekmm__castErrorKeyToLang(
                  ehm__errMessagesObject.confirm_password
                )
              "
            >
              <er-input
                style="width: 175px"
                v-model="formData.confirm_password"
                class="input-box"
                :placeholder="userFormLabels[2].placeholder"
                @keyup.native.enter="sumbitUpdatePasswordDetails"
                :maxlength="userFieldLengths.max_password_length"
                show-password
              >
              </er-input>
            </er-form-item>
          </div>
        </div>
      </er-form>
      <layout-toolbar class="form-action-wrapper" >
        <el-row class="password-validations-container">
          <span class="header">
            {{ $t("Usrs_pwd_requirement_header") }}
          </span>
          <ul class="validation-rules">
            <li
              v-for="(validation, id) in pvm__passwordValidations"
              :key="id"
              :class="[
                'validation-rules-row',
                validation.validator() && 'is-checked',
              ]"
            >
              <div class="title">
                <p>{{ ftm__capitalize(validation.content) }}</p>
              </div>
            </li>
          </ul>
        </el-row>
        <div class="filler"></div>
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :disabled="isReadOnly"
          @click="sumbitUpdatePasswordDetails"
          :loading="loading"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="resetForm"
          :disabled="isReadOnly"
        ></er-button>
      </layout-toolbar>
    </el-row>
  </div>
</template>
<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin.js";
import authErrorKeyMapMixin from "@/mixins/authErrorKeyMapMixin";
import passwordValidationMixin from "@/mixins/passwordValidationMixin";
import { mapGetters } from "vuex";
import fieldLengths from '@/constants/fieldLengths';

/* eslint-disable */

import {
  erFormItem,
  erForm,
  erInput,
  erButton,
} from "@/components/base/basePondlogs";

export default {
  mixins: [errorHandlerMixin, authErrorKeyMapMixin, passwordValidationMixin],
  components: {
    erFormItem,
    erForm,
    erInput,
    erButton,
  },
  props: ['tabData'],
  data: function () {
    return {
      userFieldLengths: fieldLengths,
      formData: {
        current_password: "",
        password: "",
        confirm_password: "",
      },
      ehm__errMessagesObject: {
        current_password: "",
        password: "",
        confirm_password: "",
      },
      loading: false,
      disabled: true,
    };
  },
  computed: {
    ...mapGetters("user", {
      isReadOnly: "isReadOnly",
    }),
    pvm__passwordValidations() {
      const password = this.formData.password || "";
      return this.pvm__passwordFieldValidations(password);
    },
    userFormLabels() {
      return [
        {
          label: this.$t("Usrs_old_password"),
          placeholder: this.$t("Usrs_enter_old_pwd"),
        },
        {
          label: this.$t("Comn_new_password"),
          placeholder: this.$t("Usrs_enter_new_pwd"),
        },
        {
          label: this.$t("Comn_confirm_pwd"),
          placeholder: this.$t("Usrs_enter_confirm_pwd"),
        },
      ];
    },
  },
  mounted() {
    this.setRefsForPasswordValidations(
      this.$refs.updatePasswordDetails,
      this.$refs.passwordField
    );
  },
  methods: {
    resetForm() {
      this.loading = true;
      this.formData = {
        current_password: "",
        password: "",
        confirm_password: "",
      };
      this.ehm__errMessagesObject = {
        current_password: "",
        password: "",
        confirm_password: "",
      };
      this.loading = false;
    },
    ehm__error403Handler(err) {
      this.ehm__unhandledErrorMessage = "Usrs_old_pwd_not_match";
    },
    ehm__error409Handler: function(err) {
      const errObject = {
        NEW_AND_CURRENT_PASSWORD_MATCHED: 'your-new-password-must-be-different-from-the-last-5-passwords',
        PASSWORD_MATCHES_LAST_5_PASSWORDS: 'your-new-password-must-be-different-from-the-last-5-passwords',
      }
      this.ehm__unhandledErrorMessage = errObject[err.response.data.error_code];
    },
    async sumbitUpdatePasswordDetails() {
      this.loading = true;
      const { password, confirm_password } = this.formData;
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.$gblUAMIsHighPriorityUsersRestricted();
        this.loading = true;
        this.ehm__errMessagesObject = {
          current_password: "",
          password: "",
          confirm_password: "",
        };
        await this.$store.dispatch("user/updatePassword", this.formData);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Usrs_pwd_change_success"),
          duration: 5000,
          type: "success",
        });
        this.$emit("password-change", true)
        this.$store.dispatch("auth/signOut");
        this.$router.push("/sign-in");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.update-password-details {
  .update_pass_form .er-form .er-form-item .el-form-item__label {
    color: #000000;
    @include small-text;
    font-weight: 700;
    .el-form--label-top {
      padding: 0 0 5px;
    }
  }

  .form-action-wrapper {
    .password-validations-container 
      {
        margin-top: 10px;
        .validation-rules  .validation-rules-row {
      flex-basis: 0;
      & + .validation-rules-row {
        margin-right: 5px;
        margin-left: 5px;
      }
    }
      }
  }
  .general-err-msg {
    font-size: 1em;
    text-align: center;
    color: red;
  }
  .er-form {
    margin-top: 20px;
    margin-bottom: 20px;
    .er-form-item {
      justify-content: center;
      .el-form-item__label {
        color: #000000;
        font-size: 1em;
        font-weight: 700;
      }
      .el-form-item__content {
        .el-input {
          color: #000000;
        }
      }
    }
  }
  .el-form-item--mini.el-form-item,
  .el-form-item--small.el-form-item {
    margin-bottom: 10px;
  }
}
</style>
